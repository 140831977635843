import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import CaseStudyHeader from "../../../components/pages/work/case-studies/CaseStudyHeader"
import CaseStudyConsultation from "../../../components/pages/work/case-studies/CaseStudyConsultation"
import CaseStudyWireframes from "../../../components/pages/work/case-studies/CaseStudyWireframes"
import CaseStudyTechnologies from "../../../components/pages/work/case-studies/CaseStudyTechnologies"
import CaseStudyResults from "../../../components/pages/work/case-studies/CaseStudyResults"
import Contact from "../../../components/pages/index/Contact"

const WalletOptimizerMobilePage = ({ pageContext, location }) => {
  const consultationDetails = {
    company: "Wallet Optimizer",
    location: "Pittsburgh, PA",
    industry: "Money Management",
    developmentType: "Landing Page",
    scope: "Design and Full Stack Development",
    businessObjectives:
      "To build a landing page that will gauge their target market's interest in a mobile financial management application. A soft commitment of name and email will suffice for this test.",

    desiredOutcomes:
      "Following the advice of the Tactic Apps team, a conversion rate of approximately 4% should be considered a successful outcome. The Wallet Optimizer team will also need a customer acquisition cost under $1.50 to build a profitable business.",

    targetMarket: "Low to medium income earners aged 27-35",
    competition: "PocketGuard",
    competitiveAdvantage: "Ease of use",
    timeFrame: "3 Weeks",
  }

  const technologies = {
    fullStack: true,
    icons: [
      "HTML5",
      "CSS3",
      "TailwindCSS",
      "JavaScript",
      "Gatsby",
      "Jest",
      "Node",
      "Git",
      "MailChimp",
    ],
  }

  const caseStudyData = {
    numBoxes: {
      developers: "1",
      hourly: "259",
      time: "0.5",
    },
  }

  const resultsImageNames = {
    results1: "Variation 1",
    results2: "Variation 2",
    results3: "Variation 3",
  }

  const resultsImageModalSize = "md"

  return (
    <StaticQuery
      query={graphql`
        {
          hero: file(
            relativePath: {
              eq: "work/landing/wallet-optimizer/wallet-optimizer-hero.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          deviceDisplay: file(
            relativePath: {
              eq: "work/landing/wallet-optimizer/wallet-optimizer-devices.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe1: file(
            relativePath: {
              eq: "work/landing/wallet-optimizer/wireframes/header.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe2: file(
            relativePath: {
              eq: "work/landing/wallet-optimizer/wireframes/steps.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe3: file(
            relativePath: {
              eq: "work/landing/wallet-optimizer/wireframes/features.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          results1: file(
            relativePath: {
              eq: "work/landing/wallet-optimizer/wallet-optimizer-landing-page-1.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 650, layout: CONSTRAINED)
            }
          }
          results2: file(
            relativePath: {
              eq: "work/landing/wallet-optimizer/wallet-optimizer-landing-page-2.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 650, layout: CONSTRAINED)
            }
          }
          results3: file(
            relativePath: {
              eq: "work/landing/wallet-optimizer/wallet-optimizer-landing-page-3.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 650, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={(data) => (
        <Layout>
          <Seo
            title="Wallet Optimizer Landing Page Case Study"
            keywords={[`wallet optimizer landing page case study`]}
            description="The Wallet Optimizer team needed a landing page to gauge their target market's interest in a mobile financial management application. This is how we built it."
          />
          <CaseStudyHeader
            image={data.hero.childImageSharp.gatsbyImageData}
            title="Landing Page Case study"
            subTitle="Wallet Optimizer"
            overlay="none"
            pageContext={pageContext}
            location={location}
            crumbLabel="Wallet Optimizer"
          />
          <main>
            <CaseStudyConsultation
              consultationDetails={consultationDetails}
              deviceImage={data.deviceDisplay}
            />
            <CaseStudyWireframes
              wireframeImages={data}
              subHeading={`After the consultation and user story workshop, we created wireframes to show ${consultationDetails.company} the potential outline of their
              landing page.`}
              companyName={consultationDetails.company}
            />
            <CaseStudyTechnologies
              technologies={technologies}
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <CaseStudyResults
              caseStudyData={caseStudyData}
              resultsImages={data}
              resultsImageNames={resultsImageNames}
              resultsImageModalSize={resultsImageModalSize}
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <Contact
              heading={
                <>
                  Let's build your{" "}
                  <span className="text-primary">
                    {consultationDetails.developmentType}
                  </span>{" "}
                  Together
                </>
              }
            />
          </main>
        </Layout>
      )}
    />
  )
}

export default WalletOptimizerMobilePage
